import { MainNavMenuAsButton, MainNavMenuLink } from "@compono/ui";
import { CandidatesOutlineIcon } from "../icons/CandidatesOutlineIcon";
import { useOrganisation } from "~/auth-layer";
import { env } from "~/env";

export const DevelopNav = () => {
  const [org] = useOrganisation();
  return (
    <MainNavMenuAsButton
      icon={CandidatesOutlineIcon}
      label="Develop"
      data-menu-button="true"
    >
      <MainNavMenuLink
        href={`https://${org.id}.${env.CLUI_DOMAIN}/sso`}
        label="SSO"
      />
    </MainNavMenuAsButton>
  );
};
