import { z } from "zod";

export const envSchema = z.object({
  AUTH0_ENV_NAME: z.string(),
  AUTH0_CUSTOM_DOMAIN: z.string(),

  AUTH0_SPA_CLIENT_ID: z.string(),

  AUTH_URL: z.string(),

  COMPONO_APP_URL: z.string(),

  ADMIN_URL: z.string(),
  CANDIDATE_URL: z.string(),
  CLUI_DOMAIN: z.string(),

  // former API_URL
  SHORTLYSTER_API_URL: z.string(),
  EMPLOYER_API_URL: z.string(),
  ENGAGE_API_URL: z.string(),
  INSIGHTS_API_URL: z.string(),
  TRACKYR_API_URL: z.string(),
  EMPLOYER_PUBLIC_API_URL: z.string(),
  EMPLOYER_PUBLIC_API_DOCS_URL: z.string(),
  FEDERATION_GATEWAY_URL: z.string(),

  ENVIRONMENT: z.string(),

  MIXPANEL_TOKEN: z.string(),
  MIXPANEL_PROXY_DOMAIN: z.string().optional(),
  SENTRY_DSN: z.string(),
  ZENDESK_TOKEN: z.string(),
  ZENDESK_TOKENURL: z.string(),

  CRONOFY_CLIENT_ID: z.string(),
  CRONOFY_CLIENT_DATACENTER: z.string(),

  GITHUB_SHA: z.string(),

  NODE_ENV: z.string(),

  APP_SHOW_DEV_VIEW: z.coerce.boolean().default(false),
  APP_SEND_ANALYTICS: z.coerce.boolean().default(false),
  APP_RUN_SENTRY: z.coerce.boolean().default(false),
  APP_RUN_ZENDESK: z.coerce.boolean().default(false),
  ENV_IS_PRODUCTION: z.coerce.boolean().default(false),
  HEADLESS_TESTING: z.coerce.boolean().default(false),
  NODE_DEBUG: z.coerce.boolean().default(false),

  LAUNCH_DARKLY_CLIENT_ID: z.string(),
});
