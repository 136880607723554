import { useQuery } from "@apollo/client";
import React from "react";

import { Sentry } from "@compono/sentry-browser";
import { Box, createContext } from "@compono/ui";

// import * as analytics from "../../../shortlyster-ui/src/lib/auth/analytics";
import { SectionLoader } from "~/components/SectionLoader";
import { useAuthentication } from "~/hooks/useAuthentication";
import { useApolloClients } from "~/hooks/useApolloClients";

import { resolvePermissions } from "./permissions";
import type { Permissions } from "./permissions";
import {
  FetchEmployerProfile,
  FetchEngagePermissions,
  FetchEmployerPermissions,
  EmployerPermissions,
  EngagePermissions as NativeEngagePermissions,
  FetchTrackyrPermissions,
  TrackyrPermissions,
} from "./queries";
import {
  Employer,
  FetchEmployerProfileData,
  Organisation,
  User,
} from "./types";

export type AuthorizationContext = {
  user: User;
  userId: string;
  orgId: string;
  organisation: Organisation;
  permissions: Permissions;
};

const [useAuthorization, AuthorizationContext] =
  createContext<AuthorizationContext>({
    name: "Authorization",
  });

const AuthorizationConsumer = AuthorizationContext.Consumer;

export type AuthorizationProviderProps = {
  children: React.ReactNode;
};

const AuthorizationProvider = ({ children }: AuthorizationProviderProps) => {
  const { logout, orgId } = useAuthentication();
  const { employerClient, engageClient, shortlysterClient, trackyrClient } =
    useApolloClients();

  // TODO @jg useEffect(() => analytics.terminate, []);

  const onError = () => {
    Sentry.flush(3000).then(() => {
      logout({ returnTo: window.location.origin });
    });
  };

  const { data, loading } = useQuery<FetchEmployerProfileData>(
    FetchEmployerProfile,
    { variables: { organisationId: orgId }, client: shortlysterClient, onError }
  );

  const { data: tpData, loading: tpLoading } = useQuery<{
    permissions: TrackyrPermissions;
  }>(FetchTrackyrPermissions, {
    variables: { organisationId: orgId },
    client: trackyrClient,
    onError,
  });

  const { data: epData, loading: epLoading } = useQuery<{
    permissions: EmployerPermissions;
    recruiterProfile: Employer;
  }>(FetchEmployerPermissions, {
    variables: { organisationId: orgId },
    client: employerClient,
    onError,
  });

  const {
    data: enpData,
    loading: enpLoading,
    error: enpError, // Cannot read properties of undefined (reading 'code') for user's without access to Engage
  } = useQuery<{
    permissions: NativeEngagePermissions;
  }>(FetchEngagePermissions, {
    variables: { orgId },
    client: engageClient,
    context: { headers: { orgId } },
    // onError, error is "normal" when user does not have engage perms
  });

  if (
    loading ||
    tpLoading ||
    enpLoading ||
    epLoading ||
    !data ||
    !tpData ||
    (!enpData && !enpError) ||
    !epData
  ) {
    return (
      <Box sx={{ height: "100vh" }}>
        <SectionLoader />
      </Box>
    );
  }

  const { currentOrganisation } = data.recruiterProfile;

  // use roles from employer gateway as it returns all roles instead of just the ones for the current product
  const allRoles =
    epData.recruiterProfile.accesses?.find((a) => a.organisationId === orgId)
      ?.allAppsRoles || [];

  const permissions = resolvePermissions(
    allRoles,
    data.permissions,
    tpData.permissions,
    epData?.permissions,
    enpData?.permissions
  );

  return (
    <AuthorizationContext.Provider
      value={{
        user: {
          ...data.profile,
          ...data.recruiterProfile,
          // overwrites with data from employer gateway
          // this is due to the fact that other gateways filter accesses by product
          accesses: epData.recruiterProfile.accesses,
        },
        userId: data.profile.id,
        orgId: currentOrganisation?.id || "",
        organisation: currentOrganisation,
        permissions,
      }}
    >
      {children}
    </AuthorizationContext.Provider>
  );
};

export {
  useAuthorization,
  AuthorizationProvider,
  AuthorizationConsumer as AuthConsumer,
  AuthorizationContext,
};
